// If you want to override variables do it here

$enable-ltr: true;
$enable-rtl: true;
$input-font-size: 14px;

$primary: #8c62db;
// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";
@import "layout";

@import 'antd/dist/antd.css';
@import 'react-big-calendar/lib/css/react-big-calendar.css';
@import 'tippy.js/dist/tippy.css';

// Global
body {
  font-size: 14px;
}

html,
body,
#root {
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.btn {
  font-size: 14px;

  &-close {
    height: auto;
  }

  &-primary {
    color: #fff;

    &.btn.disabled,
    &:hover,
    &-primary {
      color: #fff;
    }
  }

  // height: 40px;
  &.btn-outline-danger {
    color: var(--cui-btn-disabled-color);

    &:hover {
      color: #fff;
    }
  }

  &.rounded {
    padding: 0 53px;
    border-radius: 20px !important;
  }
}

.page-content-wrap {
  @media(max-width: 767px) {
    margin-top: 20px;
  }
}

.bg-rejected {
  background-color: #E32929;
}

.badge {
  border-radius: 100px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  font-size: 11px;
  font-weight: 500;

  &.bg-success {
    --cui-success: #87CB29;
  }

  &.bg-warning {
    --cui-warning: #F4822F;
    --cui-dark: #fff;
  }

  &.independent {
    background-color: #aa8be5;
  }

  &.employee {
    background-color: #7b4ed1;
  }

  &.tenant {
    background-color: #432182;
    // color: #8C62DB;
    // height: 17.33px;
    // padding-top: calc(0.35em - 1px);
    // padding-bottom: calc(0.35em - 1px);
  }
}

form {

  .card-header,
  .card-footer {
    --cui-card-cap-bg: #fff;
  }
}

.form {
  &-wrap {
    position: relative;

    input[type="password"] {
      padding-right: 35px;
    }

    .select-wrap-disabled {
      opacity: 0.7;
      pointer-events: none;
    }

    .password-toggle {
      position: absolute;
      right: 10px;
      top: 8px;
      cursor: pointer;
      filter: grayscale(1);
      font-size: 12px;

      &:before {
        content: "\1F441";
      }

      &.show:after {
        content: "";
        position: absolute;
        top: 2px;
        right: 8px;
        width: 1px;
        height: 15px;
        background: #4e4e4e;
        filter: grayscale(1);
        transform: rotate(30deg);
        transform-origin: center;
      }
    }
  }

  &-text {
    color: #f44336;
    font-size: 10px;
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 0;
  }
}

.place-autocomplete-wrap {
  position: relative;
}

.place-suggestion {
  padding: 0;
  list-style: none;
  background: #fff;
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;

  li {
    padding: 8px 20px;
    cursor: pointer;
    border-bottom: solid 1px #ebedef;

    &:hover {
      background: #e4e4e4;
    }

    &:last-child {
      border: 0;
    }
  }
}

.disclaimer {
  background: #f9f9f9;
  padding: 15px 20px 10px;
  margin: 0 -16px -16px;
  border-radius: 10px;
  position: relative;
  z-index: 1;

  @media (max-width: 575px) {
    overflow: hidden;
  }

  &:after {
    position: absolute;
    content: "";
    inset: 0;
    background: #f9f9f9;
    right: calc(-100% - -8px);
    z-index: -1;
  }
}

.custom-table {
  @media (max-width: 767px) {
    .btn {
      font-size: 11px;
    }
  }

  @media (max-width: 374px) {
    .btn {
      font-size: 10px;
    }
  }

  &.table-mod {
    --cui-table-hover-bg: transparent;
    --cui-table-color: #4f5d73;

    tr {
      position: relative;
    }

    th {
      font-weight: bold;
      border-bottom: 2px solid #f1f1f1;
      border-top: 2px solid #f1f1f1;
      padding: 0.75rem;

      @media (max-width: 374px) {
        padding: 0.75em 5px;
      }
    }

    td {
      border-bottom-width: 0;
      border-top: 1px solid #f1f1f1;
      padding: 5px 12px;

      @media (max-width: 374px) {
        padding: 0.75em 5px;
      }
    }

    thead {
      --cui-table-bg: transparent;
    }
  }

  >thead {
    --cui-table-bg: #f1f1f1;
  }

  // th.actions {
  //   padding-right: 20px;
  // }
  .actions {

    // width: 1%;
    // white-space: nowrap;
    .btn-sm {
      border-radius: 5px;
      font-weight: bold;
      padding: 4px 14px;
      border-width: 2px;
    }

    >div {
      display: flex;
      // justify-content: flex-end;
    }
  }
}

.dashboard-cards {
  margin: 0 -8px;

  .card {
    border-width: 0;
    flex: 0 0 calc(33.33% - 1rem);
    --cui-high-emphasis: #fff;
    --cui-medium-emphasis: #fff;
    transition: box-shadow 0.3s;
    background: linear-gradient(to right, #8c62db 0%, #a162db 60%);

    &:hover {
      box-shadow: 2px 2px 8px rgb(0 0 0 / 20%);
    }

    &-highlight {
      background: linear-gradient(to right, #ff6b89 0%, #ff6bae 60%);
      --cui-high-emphasis: var(--cui-white);
      --cui-medium-emphasis: var(--cui-white);
    }
  }

  .progress {
    display: none;
  }
}

.table-card {
  margin: 0 -28px;
  border-radius: 0;
  border-width: 0;
  .card-footer {
    padding-left: 65px;
    ul {
      margin-bottom: 0;
    }
  }
  @media(max-width: 767px) {
    margin: 0 -15px;
  }

  .card-body {
    padding: 0;
  }

  .custom-table {

    td:first-child,
    th:first-child {
      padding-left: 65px;
      @media(max-width: 767px) {
        padding-left: 15px;
      }
    }
    td:last-child,
    th:last-child {
      padding-right: 40px;
      @media(max-width: 767px) {
        padding-right: 15px;
      }
    }
    tr:last-child td {
      border-bottom: solid 1px #F1F1F1;
    }
  }
}

.payout {
  width: 400px;
  max-width: 100%;
}

.in-active {
  opacity: 0.2;
  pointer-events: none;
}

.text-banner {
  margin-bottom: 24px;
  padding: 15px;
  background-color: var(--cui-red);
  color: var(--cui-white);
}

.neutral-helper-text {
  color: #808080;
}

.coverimage-text {
  position: static;
  margin-left: 0;
}

.upcoming-buttons-column {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;

  .btn {
    white-space: nowrap;
  }
}

.share-cell,
.share-label {
  white-space: nowrap;
}
.share-cell {
  .share-label {
    display: flex;
    align-items: center;
  }
}

.share-label {
  &.highlight {
    color: #8fd14f;
  }

  color: #4F5D73;
}

.modal-title {
  span {
    font-weight: normal;
  }
}

.artist-avatar {
  width: 33px;
  height: 33px;
  flex-shrink: 0;
}

.bg {
  &-scheduled {
    background-color: #87CB29;
    &.event-label{
      background-color: #F1F9E7;
      border-color: #87CB29;
      @media(max-width: 767px) {
        .rbc-month-view & {
          background-color: #87CB29;
        }
      }
    }
  }

  &-pending {
    background-color: #8C62DB;
    &.event-label{
      background-color: #e7dbff;
      border-color: #8C62DB;
      @media(max-width: 767px) {
        .rbc-month-view & {
          background-color: #8C62DB;
        }
      }
    }
  }

  &-postponed {
    background-color: #F4822F;
    &.event-label{
      background-color: #ffecde;
      border-color: #F4822F;
      @media(max-width: 767px) {
        .rbc-month-view & {
          background-color: #F4822F;
        }
      }
    }
  }
}

.popup-line-full-width {
  .btn {
    &:first-child {
      margin-left: 0 !important;
    }
  }

  @media (min-width: 768px) {
    margin: 0 -32px;
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  @media (max-width: 767px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.tooltip-inner {
  padding: 10px;
}

.artist-action {
  svg.icon.ms-3 {
    @media (max-width: 374px) {
      margin-left: 10px !important;
    }
  }
}

.bg-payout-success {
  background-color: #91d04f;
}

.modal-lg {
  @media (min-width: 768px) {
    max-width: 500px;
  }
}

.price-calculation {
  background-color: #f4f4f4;
  padding: 10px;
}

.service-fee {
  background-color: #e6e6e6;
  padding: 10px;
}

@media (max-width: 767px) {
  .title-xs-history {
    max-width: 175px;
  }

  .title-xs-artist {
    max-width: 190px;
  }

  .title-xs-upcoming {
    max-width: 160px;
  }
}

.btn-outline-primary {
  --cui-btn-border-color: #3399ff;
  --cui-btn-color: var(--cui-btn-border-color);
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: var(--cui-btn-border-color);
  --cui-btn-hover-border-color: var(--cui-btn-border-color);
  --cui-btn-active-color: var(--cui-btn-hover-color);
  --cui-btn-active-bg: var(--cui-btn-border-color);
  --cui-btn-active-border-color: var(--cui-btn-border-color);
}

.btn-outline-info {
  --cui-btn-border-color: #321fdb;
  --cui-btn-color: var(--cui-btn-border-color);
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: var(--cui-btn-border-color);
  --cui-btn-hover-border-color: var(--cui-btn-border-color);
  --cui-btn-active-color: var(--cui-btn-hover-color);
  --cui-btn-active-bg: var(--cui-btn-border-color);
  --cui-btn-active-border-color: var(--cui-btn-border-color);
  --cui-btn-disabled-border-color: var(--cui-btn-border-color);
  --cui-btn-disabled-color: var(--cui-btn-border-color);

  &.disabled {
    opacity: 0.5;
  }
}

.btn-outline-dark {
  --cui-btn-border-color: #4F5D73;
  --cui-btn-color: var(--cui-btn-border-color);
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: var(--cui-btn-border-color);
  --cui-btn-hover-border-color: var(--cui-btn-border-color);
  --cui-btn-active-color: var(--cui-btn-hover-color);
  --cui-btn-active-bg: var(--cui-btn-border-color);
  --cui-btn-active-border-color: var(--cui-btn-border-color);
  --cui-btn-disabled-border-color: var(--cui-btn-border-color);
  --cui-btn-disabled-color: var(--cui-btn-border-color);
}

.btn-outline-success {
  border-width: 2px;
  --cui-btn-border-color: #87CB29;
  --cui-btn-color: var(--cui-btn-border-color);
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: var(--cui-btn-border-color);
  --cui-btn-hover-border-color: var(--cui-btn-border-color);
  --cui-btn-active-color: var(--cui-btn-hover-color);
  --cui-btn-active-bg: var(--cui-btn-border-color);
  --cui-btn-active-border-color: var(--cui-btn-border-color);
  --cui-btn-disabled-border-color: var(--cui-btn-border-color);
  --cui-btn-disabled-color: var(--cui-btn-border-color);
}

.btn-outline-danger {
  --cui-btn-border-color: #E32929;
  --cui-btn-color: var(--cui-btn-border-color);
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: var(--cui-btn-border-color);
  --cui-btn-hover-border-color: var(--cui-btn-border-color);
  --cui-btn-active-color: var(--cui-btn-hover-color);
  --cui-btn-active-bg: var(--cui-btn-border-color);
  --cui-btn-active-border-color: var(--cui-btn-border-color);
  --cui-btn-disabled-border-color: var(--cui-btn-border-color);
  --cui-btn-disabled-color: var(--cui-btn-border-color);
}

.modal-footer {
  justify-content: flex-start;

  >div:empty {
    display: none;
  }
}

.custom-modal-footer {
  margin: 0 -16px;
  padding-left: 16px !important;
  padding-right: 16px !important;

  .btn {
    &:first-child {
      margin-left: 0 !important;
    }
  }
}

.list-bg {
  background-color: #f4f4f4;
  margin-bottom: 10px !important;
  padding: 10px 0;
}

.share-pending-bg {
  background-color: #cee740;
  color: #fff;
  padding: 10px;
  position: relative;
  z-index: 1;
}

.share-active-bg {
  background-color: #f4f4f4;
  color: #1a1a1a;
  padding: 10px;
  position: relative;
  z-index: 1;
}

.upcoming {
  &-status {
    >div {
      justify-content: center;
    }
  }
  &-card {
    width: 395px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    background: #FCFCFC;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 15px 15px 20px;
    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    > div + div {
      border-top: solid 1px #EBEDEE;
      padding-top: 15px;
      margin-bottom: 15px;
    }
    p {
      margin: 0;
    }
    &-header{
      display: flex;
      align-items: center;
      padding-bottom: 15px;
      img {
        display: block;
        width: 65px;
        height: 65px;
        object-fit: cover;
        border-radius: 50%;
      }
      span {
        font-size: 16px;
        font-weight: 600;
        margin-left: 15px;
      }
    }
    .proj-details {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .style {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 3px;
      }
      .customer {
        font-size: 12px;
        color: #9B9B9B;
      }
      .price {
        font-size: 16px;
        font-weight: 600;
        display: flex;
        span {
          margin-right: 10px;
        }
      }
    }
    .proj-status {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
    }
    &-modal {
      .modal {
        &-body {
          padding: 0;
        }
        &-dialog {
          max-width: 95%;
          width: 385px;
          margin: auto;
        }
      }
    }
  }
}

.modal-header.border-0 .btn-close {
  margin-right: -15px;
}

.sidebar {
  background-color: #423660;
  @media(max-width: 767px) {
    padding-top: 55px;
  }
  &-brand {
    background-color: #332a4b;
    padding: 0 16px;
    justify-content: flex-start;
  }

  &-nav .nav-link.active,
  &-nav .nav-link:hover {
    background-color: #544676;
    color: #cdb9ff;

    .nav-icon {
      color: #cdb9ff;
    }
  }

  &-nav .nav-group.show .nav-group-toggle {
    color: #cdb9ff;

    .nav-icon {
      color: #cdb9ff;
    }
  }

  .nav-link {
    color: #8b7ab5;
  }

  &-nav {
    .nav-icon {
      color: #8b7ab5;
    }
  }

  .nav-group.show {
    background-color: #4b3e6c;
  }
}
.share-status {
  &-badge {
    font-size: 10px;
    height: 15px;
    color: #fff;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    .status-icon {
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .share-status-current & {
        background-color: #74B51A;
      }
      .share-status-pending & {
        background-color: #E5823A;
      }
    }
    .share-status-current & {
      background-color: #87CB29;
    }
    .share-status-pending & {
      background-color: #F89B58;
    }
    .status-text {
      padding: 0 8px 0 3px;
    }
  }
}
.share-text {
  width: 37px;
  .share-status-pending & {
    color: #F89B58;
  }
}
.page-title{
  padding-left: 45px !important;
  font-size: 20px;
  font-weight: 600;
  color: #4F5D73;
  @media(max-width: 767px) {
    display: none;
  }
}
.card {
  &.position-adj {
    margin: 0 -28px;
  }
}
.view-nav {
  display: inline-flex;
  height: 33px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  font-size: 14px;
  cursor: pointer;
 
  &-bar {
    background-color: #FAFAFA;
    margin: -24px -28px 0;
    padding-right: 40px;
    height: 58px;
    display: grid;
    align-items: center;
    text-transform: capitalize;
    grid-template-columns: 160px 1fr 1fr;
    padding-left: 65px;

    .monthpicker-bar {
      justify-self: end;
      margin: 0;
    }
    @media(max-width: 767px) {
      padding-left: 15px;
      margin: 0 -15px;
      background: transparent;
    }
  }
  > div {
    line-height: 33px;
    min-width: 80px;
    text-align: center;
    +div {
      .cal-toolbar & {
        border-left: solid 2px #E2E2E2;
      }
      .cal-filter-modal & {
        border-left: solid 1px #E2E2E2;
      }
    }
    &.active {
      font-weight: 700;
      background-color: #493E69;
      color: #fff;
    }
  }
}
.artist {
  &-info {
    flex-grow: 1;
    .badge {
      height: 18px;
      font-size: 10px;
    }
  }
  &-label {
    text-align: right;
    margin-bottom: 15px;
  }
  &-avatar {
    &-wrap {
      width: 65px;
      height: 65px;
      position: relative;
      .status-icon {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    &-img {
      width: 63px;
      height: 63px;
      border-radius: 50%;
      overflow: hidden;
      margin: auto;
      img {
        width: 100%;
      }
    }
  }
  &-card {
    background: #FCFCFC;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 12px;
    display: grid;
    &-wrap {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 22px;
      padding-top: 10px;
      .alert-info {
        grid-column: 1/-1;
      }
      @media(min-width: 768px) {
        padding-top: 38px;
        padding-left: 37px;
      }
    }
    &-det-wrap {
      border-top: solid 1px #EBEDEE;
      @media(max-width: 767px) and (orientation: portrait) {
        display: none;
        &.pending {
          display: block;
        }
        &.open {
          display: block;
        }
      }
    }
    &-header {
      display: flex;
      padding-bottom: 16px;
      align-self: start;
      .artist-name {
        font-size: 14px;
        margin-left: 10px;
        color: #4F5D73;
        @media(max-width: 767px) {
          font-size: 18px;
        }
      }
    }
    &-body {
      padding-top: 14px;
      padding-bottom: 6px;
      border-bottom: solid 1px #EBEDEE;
    }
    &-footer {
      align-self: end;
      .footer-btn {
        display: flex;
        justify-content: center;
        margin-top: 23px;
        .btn {
          height: 36px !important;
          border-radius: 5px;
          font-weight: 600;
          font-size: 11px;
          box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
          border-width: 2px;
        }
      }
      .contact-btn {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;
        .inner-btn {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
          margin: 0 6px;
          background-color: #fff;
          position: relative;
          z-index: 1;
          cursor: pointer;
        }
        .phone-number {
          position: absolute;
          width: auto;
          padding: 0 15px;
          right: calc(-100% - 15px);
          z-index: 0;
          font-size: 10px;
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
}
.progress {
  --cui-progress-bg: #F3F3F3;
  height: 5px;
  border-radius: 50px;
  overflow: hidden;
  margin-bottom: 6px;
  &.pending {
    --cui-progress-bar-bg: #E5823A;
  }
  &-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 8px;
    @media(max-width: 767px) {
      font-weight: 400;
    }
    &.active {
      .status-icon {
        background-color: #74B51A;
      }
    }
    &.pending {
      color: #E5823A;
      .status-icon {
        background-color: #E5823A;
      }
    }
    .status-icon {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
    }
    > div {
      display: flex;
      align-items: center;
    }
  }
}
.page-title-xs {
  display: none;
  background: #FAFAFA;
  min-height: 56px;
  align-items: center;
  margin: -24px -15px 0;
  padding: 0 15px;
  font-size: 18px;
  color: #4F5D73;
  font-weight: 600;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  @media(max-width: 767px) {
    display: flex;
  }
}
.cust-datepicker {
  height: 33px;
  width: 160px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &.ant-picker-focused {
    background-color: #493E69;
    .ant-picker-input > input {
      cursor: pointer;
      color: #fff;
    }
    color: #fff;
    .ant-picker-suffix {
      color: #fff;
      transform: rotate(180deg);
      transform-origin: center;
    }
    .ant-picker-input > input::placeholder {
      color: #fff;
    }
  }
  .ant-picker-input > input::placeholder {
    color: #4F5D73;
  }
  .ant-picker-suffix {
    color: #493E69;
    display: flex;
    align-items: center;
    img {
      display: block;
    }
  }
}
.ant-picker-panel-container {
  border-radius: 5px;
}
.ant-picker-month-panel {
  width: 257px;
  height: auto;
  background: #FFFFFF;
  .booking-cal & {
    width: 100%;
  }
  .ant-picker-content {
    height: auto;
  }
  td.ant-picker-cell.ant-picker-cell-in-view {
    height: 28px;
    border: 0.5px solid #DADCDF;
    border-radius: 5px;
    text-align: center;
    font-size: 9px;
    &.ant-picker-cell-selected {
      border-color: #4F5D73;
    }
  }
  .ant-picker-super-prev-icon,
  .ant-picker-super-next-icon {
    color: #4F5D73;
    &:before {
      display: none;
    }
  }
}
.ant-picker-panel-container {
  .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 50%;
  }
  .ant-picker-content {
    .ant-picker-cell-in-view {
      color: #4F5D73;
    }
    th, td {
      width: 32px;
      padding: 0;
      font-size: 9px;
    }
  }
  .ant-picker-week-panel,
  .ant-picker-date-panel {
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      display: none;
    }
  }
  .ant-picker-header {
    border: none;
    padding: 0 15px;
    margin-bottom: 5px;
  }
  .ant-picker-body {
    padding: 0 15px 10px;
  }
  .ant-picker-content {
    border-collapse: separate;
    border-spacing: 4px;
  }
  .ant-picker-cell-inner {
    height: 32px;
    width: 32px;
    line-height: 32px;
  }
  .ant-picker-cell-today {
    .ant-picker-cell-inner {
      &:before {
        border: 0.5px solid #4F5D73;
        border-radius: 50%;
      }
    }
  }
  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background-color: #493E69 !important;
    }
  }
  .ant-picker-week-panel-row {
    td {
      border-radius: 50%;
    }
    &:hover td {
      background: rgba(73, 62, 105, 0.05);
    }
    &-selected {
      td {
        background-color: #493E69 !important;
        color: #fff !important;
      }
    }
  }
}
.ant-picker-week-panel {
  tr {
    th:first-child,
    td:first-child {
      display: none;
    }
  }
}
.monthpicker-bar {
  background-color: #FAFAFA;
  margin: 0 -28px 0;
  height: 58px;
  display: flex;
  align-items: center;
  padding-left: 65px;
  @media(max-width: 767px) {
    padding-left: 30px;
    // margin: 0;
  }
}

.payment-card {
  background: #FCFCFC;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  border-radius: 5px;
  padding: 12px;
  display: flex;
  align-items: flex-start;
  &-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
    gap: 12px;
    div:empty {
      display: none;
    }
  }
  .user-img {
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    width: 64px;
    img {
      display: block;
      max-width: 100%;
    }
  }
  .content {
    flex-grow: 1;
    p {
      margin: 0;
      &.price {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .name {
    font-weight: 600;
    font-size: 16px;
  }
  .footer-det {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}
.reciept-card {
  background: #FCFCFC;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  &-wrap {
    display: grid;
    gap: 15px;
    margin-top: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  p {
    margin: 0;
  }
  .date {
    font-size: 16px;
    font-weight: 600;
  }
  .id {
    opacity: 0.6;
    font-size: 12px;
  }
  .price {
    font-size: 18px;
    margin-left: 10px;
    font-weight: 600;
  }
  .amount {
    display: flex;
    align-items: baseline;
  }
  .bottom-cont {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .detail-btn {
    cursor: pointer;
  }
}
.calendar-wrap {
  height: 80vh;
  margin: 0 -28px;
  @media(orientation: landscape) and (pointer: coarse) {
    height: 600px;
  }
  @media(orientation: landscape) and (max-width: 767px) {
    .rbc-month-view {
      min-height: 450px;
      max-height: 450px;
    }
  }
  @media(max-width: 767px) {
    margin: 0 -15px;
    &:not(.month-view) {
      height: auto;
    }
    .rbc-month-view {
      max-height: 83vw;
      border-width: 0;
      padding: 0 15px;
    }
    .rbc-date-cell {
      text-align: center;
    }
    .rbc-month-header {
      margin: 20px 0 10px;
    }
  }
}
.cal-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  height: 85px;
  padding-right: 25px;
  padding-left: 65px;
  @media(max-width: 767px) {
    background-color: #FAFAFA;
    padding: 0 20px;
    height: 56px;
    position: sticky;
    top: 56px;
    z-index: 11;
  }
  .toolbar-picker {
    width: 296px;
    position: relative;
    cursor: pointer;
    height: 33px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    border-radius: 5px;
    @media(max-width: 767px) {
      width: 200px;
    }
  }
  .monthpicker-bar {
    margin: 0 auto;
    padding: 0;
    height: 100%;
  }
  .cust-datepicker {
    width: 296px;
    padding: 0;
    @media(max-width: 767px) {
      width: 200px;
    }
  }
}
.cal-header-label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  inset: 0;
  z-index: 1;
  visibility: hidden;
  span {
    flex-grow: 1;
    pointer-events: none;
  }
  > div {
    width: 33px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }
  div,span {
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
  }
}
.booking-cal {
  width: 296px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.event-label {
  text-transform: capitalize;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
}
.rbc {
  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: #4F5D73;
    font-size: 12px;
    border-bottom-width: 0;
    + .rbc-header {
      border-left-width: 0;
    }
    &.rbc-today {
      font-weight: 700;
    }
    .rbc-month-view & {
      @media(min-width: 768px) {
        display: none;
      }
    }
  }
  &-show-more {
    color: #4F5D73;
    font-size: 12px;
    text-decoration: none;
    font-weight: 500;
    padding: 5px;
  }
  &-event-content {
    @media(min-width: 768px) {
        min-height: 4.87vh;
    }
    @media(max-width: 767px) {
      max-width: 97%;
    }
    .event-label {
      .rbc-month-row & {
        @media(min-width: 768px) {
          padding: 7px 10px;
        }
      }
    }
  }
  &-allday-cell {
    display: none;
  }
  &-today {
    background-color: #fff;
  }
  &-time {
    &-gutter {
      width: 65px;
      @media(max-width: 767px) {
        max-width: 45px;
      }
    }
    &-header {
      @media(max-width: 767px) {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
        z-index: 11;
        background-color: #fff;
        position: sticky;
        top: 112px;
        .rbc-time-header-content {
          min-width: 50vw;
        }
      }
    }
    &-view-resources .rbc-day-slot {
      @media(max-width: 767px) {
        min-width: 50vw;
      }
    }
    &-header-gutter {
      border-right-width: 0 !important;
    }
    &-header-content {
      border-width: 0;
      > .rbc-row.rbc-row-resource {
        border-bottom-width: 0;
      }
    }
    &-slot {
      text-align: right;
      .rbc-label {
        font-size: 10px;
        color: #989898;
      }
    }
    &slot {
      &-group {
        border-bottom: 2px solid #F1F1F1;
        .rbc-time-gutter & {
          border-bottom-width: 0;
        }
      }
    }
    &-view-resources {
      @media(max-width: 767px) {
        .rbc-time-gutter {
          border-right-width: 0;
        }
      }
      .rbc-time-content > * + * > * {
        @media(max-width: 767px) {
          border-left: 1px solid #EFEFEF;
        }
      }
    }
    &-content {
      border-top: 1px solid #F1F1F1;
      > * + * > * {
        border-color: #F1F1F1;
        @media(max-width: 767px) {
          border-left-width: 0;
          border-bottom: 0.5px solid #EAEAEA;
        }
      }
    }
    &-view {
      border-color: #F1F1F1;
      .rbc-row {
        min-height: 41px;
      }
    }
  }
 &-day-slot {
  @media(max-width: 767px) {
    padding-top: 13px;
  }
  .rbc-events-container {
    margin-right: 0;
  }
   &.rbc-today {
    background: #493E690D;
   }
  .rbc-time-slot {
    border: none;
  }
  .event-label{
    padding: 7px 10px;
    height: 100%;
  }
 }
  &-event,
  &-event.rbc-selected {
    background: transparent;
    border-width: 0 !important;
    color: black;
    padding: 0;
    @media(max-width: 767px) {
      .rbc-month-view & {
        width: 5px;
        height: 5px;
        overflow: hidden;
        border-radius: 50%;
        margin: auto;
        background-color: #87CB29;
        position: absolute;
        left: 0;
        right: 0;
        top: 2px;
      }
    }
  }
  &-row {
    &-content {
      @media(max-width: 767px) {
        .rbc-row:nth-child(n+4) {
          display: none;
        }
        .rbc-row:nth-child(n+3) .rbc-event {
          left: 17px;
          top: 1px;
      }
      }
    }
    &-segment {
      @media(max-width: 767px) {
        position: relative;
      }
    }
  }
  &-show-more {
    @media(max-width: 767px) {
      // opacity: 0;
    }
  }
  &-day-bg {
    &.rbc-today {
      background-color: #493E690D;
      @media(max-width: 767px) {
        background-color: transparent;
      }
    }
    + .rbc-day-bg {
      border-color: #F1F1F1;
      @media(max-width: 767px) {
        border-width: 0;
      }
    }
  }
  &-month-row {
    .rbc-date-cell {
      padding: 5px 13px 0 0;
    }
    @media(max-width:767px) {
      justify-content: center;
      .rbc-date-cell {
        padding: 0;
        &.rbc-now > a {
          border-width: 0.5px;
        }
        &.rbc-current > a {
          background: #322A49;
          color: #fff;
        }
        > a{
          border: 0.5px solid #322A49;
          border-width: 0;
          border-radius: 50%;
          width: 8vw;
          height: 8vw;
          display: flex;
          margin: auto;
          align-items: center;
          justify-content: center;
          font-size: 14px;
        }
      }
    }
  }
  &-month-row + .rbc-month-row {
    border-color: #F1F1F1;
    @media(max-width: 767px) {
      border-width: 0;
    }
    
  }
  &-off-range {
    color: #D0D0D0;
    &-bg {
      background-color: #fff;
    }
  }
  &-date-cell.rbc-now {
    font-weight: 400;
  }
  &-event {
    &-label {
      display: none;
    }
    &-content {
      span {
        display: block;
      }
      .bg {
        &-scheduled {
          .status {
            color: #87CB29;
          }
        }
        &-pending {
          .status {
            color: #8C62DB;
          }
        }
        &-postponed {
          .status {
            color: #F4822F;
          }  
        }
      }
      .status {
        // color: #77BB20;
        font-size: 12px;
        font-weight: 600;
      }
      .text {
        color: #646464;
        margin-top: 8px;
        font-size: 13px;
        font-weight: 700;
        .rbc-month-row & {
          display: none;
        }
      }
    }
  }
}
.tippy {
  &-box {
    background-color: #fff;
    color: #4F5D73;
    max-width: 385px !important;
  }
  &-content {
    padding: 0;
  }
  &-arrow {
    color: #fff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  }
}
.artist-select {
  width: 160px;
  margin-left: 15px;
  &-dropdown {
    width: 257px !important;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    border-radius: 5px;
    padding: 0;
    z-index: 1061;
    @media(max-width: 767px) {
      // left: 0 !important;
      // top: 0 !important;
    }
    .ant-select-item-option {
      padding: 10px 12px !important;
      min-height: auto !important;
      &-selected:not(.ant-select-item-option-disabled) {
        color: #fff;
        font-weight: 600;
        background-color: #493d69;
      }
    }
  }
  .ant-select-arrow {
    color: #493E69;
  }
  &.ant-select-open {
    .ant-select-arrow {
      color: #fff;
      transform: rotate(180deg);
    }
  }
  &.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      height: 33px;
      border-width: 0;
    }
  }
  &.ant-select:not(.ant-select-disabled):hover,
  &.ant-select-open {
    .ant-select-arrow {
      color: #fff;
    }
    .ant-select-selector {
      border-color: #493E69;
      background-color: #493E69;
      color: #fff;
    }
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) 
  .ant-select-selector {
    border: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }
}
.resource-header {
  display: flex;
  align-items: center;
  padding: 20px 0;
  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: block;
    margin-right: 10px;
  }
  span {
    font-size: 10px;
    color: #4F5D73;
    font-weight: 700;
    text-align: left;
    display: block;
    @media(max-width: 767px) { 
      font-size: 12px;
      display: inline;
    }
  }
}
.filter-info-wrap {
  display: flex;
  align-items: center;
  .artist-img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    overflow: hidden;
    border: solid 2px #77BB20;
    display: flex;
    align-items: center;
    justify-content: center;
    &.artist-group {
      img {
        width: 100%;
      }
    }
    img {
      display: block;
      width: 29px;
      border-radius: 50%;
    }
  }
  .settings-trigger {
    width: 33px;
    height: 33px;
    margin-left: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.cal-filter-modal {
  transition: none;
  &.modal.fade .modal-dialog {
    transition: none;
  }
  .modal-body {
    padding-top: 70px;
  }
  h2 {
    font-size: 16px;
    color: #4F5D73;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .view-nav {
    margin-bottom: 30px;
    text-transform: capitalize;
  }
  .artist-select {
    margin-left: 0;
    width: 100%;
  }
  .modal-footer {
    height: 70px;
    background-color: #87CB29;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      font-size: 12px;
      height: 40px;
      margin: 0;
      padding: 0 30px;
      &-outline-light {
        --cui-btn-border-color: #fff;
        --cui-btn-color: #fff;
      }
      &-dark {
        --cui-btn-border-color: #4B9306;
        --cui-btn-color: #fff;
        --cui-btn-bg: #61A614;
        --cui-btn-hover-color: #fff;
        --cui-btn-hover-bg: #4B9306;
        --cui-btn-hover-border-color: #4B9306;
      }
    }
  }
  .modal-close {
    position: absolute;
    top: 20px;
    right: 25px;
    z-index: 1;
  }
}
.mobile-week-header {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  padding: 20px 0 10px;
  position: sticky;
  top: 112px;
  background-color: #fff;
  z-index: 1;
  > div {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &.active {
      .date {
        background: #322A49;
        color: #fff;
      }
    }
  }
  .day {
    font-size: 10px;
    color: #4F5D73;
    font-weight: 700;
    margin-bottom: 14px;
  }
  .date {
    width: 30px;
    height: 30px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}
