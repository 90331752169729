.wrapper {
    position: relative;
    width: 100%;
    @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
    will-change: auto;
    @include transition(padding .15s);
    .body {
      @media(max-width: 767px) {
        padding: 0 3px;
      }
    }
  }
  