.section-header {
    margin-bottom: 24px;
    padding: 0 14px;
}
.tran-box {
    .label {
        flex: 0 0 calc(75% - 100px);
        max-width: calc(75% - 100px);
    }
    &.transaction {
        background: #F3F3F3;
    }
    &.total {
        color: #61A614;
        background-color: #DDF0C3;
        font-weight: bold;
    }
    min-height: 48px;
    border-radius: 3px;
    > div {
        display: flex;
        justify-content: space-between;
    }
    .divider {
        width: 120px;
        height: 1px;
        margin: 7px 0;
        background: #D0D0D0;
    }
}
.currency-wrap {
    flex: 1 0 100px;
    max-width: 100px;
}
.xs-action-none {
    display: none;
}
@media (max-width: 990px) and (pointer: coarse) and(orientation: portrait), 
(max-width: 990px) and (orientation: landscape) and (pointer: coarse) {
    .show-xs {
        display: block;
    }
    .show-xs-table-cell {
        display: table-cell !important;
    }
    .hide-xs {
        display: none !important;
    }
}