:root{
    --customerCard: #433569;
    --artistCard: #8361DF;
    --leadsCard: #4C7DC5;
    --contactCustomerCard: #797979;
    --filterChipBg: #EBEBEB;
    --filterChipTextColor: #333333;
    --filterBtnBg: #EBEBEB;
    --filterBtnActive: #433569;
}
* {
    padding: 0;
    margin: 0;
    font-family: 'Mulish', sans-serif;
}
html, body {
    background-color: #F9F9F9 !important;
}
.analytics-layout {
    padding-top: 40px;
    padding-bottom: 50px;
}
.analytics-header {
    background-color: #332A4B;
    padding: 20px 0;
}
.analytics-card {
    position: relative;
    padding: 16px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}
.analytics-card .loader-component {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.35s ease-in-out;
    z-index: -10;
}
.analytics-card .loader-component.loading {
    opacity: 1;
    z-index: 15;
}

/* .analytics-card .card-inner {
    opacity: 1;
    transition: 0.35s ease-in-out;
}
.analytics-card .card-inner.loading {
    opacity: 0.2;
} */
.analytics-card.customerCard {
    background-color: var(--customerCard);
}
.analytics-card.artistCard {
    background-color: var(--artistCard);
}
.analytics-card.leadsCard {
    background-color: var(--leadsCard);
}
.analytics-card.contactCustomerCard {
    background-color: var(--contactCustomerCard);
}
.filter-chip{
    background-color: var(--filterChipBg);
    color: var(--filterChipTextColor);
    height: 22px;
    padding: 0 14px;
    border-radius: 50px;
    opacity: 1;
    transition: 0.25s ease-in-out;
}
.filter-chip.chip-disabled {
    opacity: 0;
}

.filter-chip h6 {
    margin-bottom: 0;
    line-height: 22px;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 600;
}
.filter-button {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: var(--filterBtnBg);
    color: #000;
    border: none;
    opacity: 1;
    transition: 0.15s ease-in-out;
}
.filter-button.global-active {
    opacity: 0.5;
    /* pointer-events: none; */
    cursor: not-allowed;
}
.filter-button.active-btn{
    background-color: var(--filterBtnActive);
    color: #fff;
}
.gloabl-inp {
    pointer-events: none;
    cursor: not-allowed;
}
.filter-button-text {
    display: inline-block;
    padding: 0 15px;
    border-radius: 5px;
    line-height: 30px;
    height: 30px;
    text-align: center;
    border: none;
    background-color: var(--filterBtnBg);
    font-size: 12px;
    font-weight: 600;
    transition: 0.15s ease-in-out;
}
.active-filter-btn {
    background: #332A4B;
    color: #fff;
}
.card-action-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}
.card-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-content {
    margin-top: 20px;
}
.count-content h4{
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 600;
    color: var(--customerCard);
}
.count-content h6 {
    font-size: 15px;
    margin-bottom: 0;
}
.detail-content {
    margin-bottom: 30px;
}
.detail-content h2 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}
.detail-content p {
    margin-bottom: 0;
    color: #000;
    font-size: 14px;
}
.spinner-border {
    border-width: 2px !important;
}
.spacer {
    height: 40px;
}
.currency-li {
    margin: 0;
    padding: 0;
    font-size: 15px;
    color: #424242;
    list-style: none;
}
.currency-li li {
    margin-top: 10px;
}
.datepicker-input {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0;
    top: -20px;
    opacity: 0;
    cursor: pointer;
}

.filter-data {
    font-size: 12px;
    text-align: right;
    margin-top: 0.5rem;
}
.filter-data span {
    font-weight: 600;
    text-transform: capitalize;
}

@media only screen and (max-width: 600px){
    .detail-content {
        margin-bottom: 0;
    }
    .card-action-row {
        justify-content: center;
        gap: 3px;
    }
    .filter-data {
        text-align: center;
    }
}