.header {
  .wrapper & {
    --cui-header-border-width: 0;
    &.header-sticky {
      z-index: 1051;
    }
    @media(max-width: 767px) {
      --cui-header-color: #fff;
      --cui-header-active-color: #fff;
      background-color: #322A49;
      min-height: 54px;
      .container-fluid {
        min-height: 100% !important;
      }
    }
  }
  .account-dd-wrapper .dropdown-menu {
    min-width: 250px;
    max-width: 100%;
  }

  .notification-wrapper .dropdown-menu {
    min-width: 400px;
    max-width: 100%;
    @media(max-width: 767px) {
      min-width: auto;
      max-width: 100vw;
    }
  }
  .notification {
    &-titile {
      font-size: 14px;
      font-weight: 700;
    }
    &-text {
      font-size: 80%;
      p {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &-view-all {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .notification {
    &-wrapper {
        top: 1px;
    }
    &-count {
      position: absolute;
      color: #fff;
      border-radius: 50%;
      background-color: #FF6A70;
      display: flex;
      width: 8px;
      height: 8px;
      text-align: center;
      font-size: 10px;
      align-items: center;
      justify-content: center;
      right: 15px;
      top: -8px;
      line-height: 1;
      span {
        line-height: 8px;
      }
    }
    &-time {
      .text-muted {
        font-size: 11px;
      }
    }
    &-text {
      font-size: 14px;
    }
  }
  @media(max-width: 767px) {
    padding: 8px 3px !important;
    &-toggler {
      padding-left: 0 !important;
      &.show {
        pointer-events: none;
      }
    }
  }
}
@media(pointer: coarse) {
  .header-nav .nav-link:hover, .header-nav .nav-link:focus {
    color: #fff !important;
  }
}